import { createAction, createSlice } from '@reduxjs/toolkit';
import { ConversionType } from '../../../../pages/addPoint/conversionRate';

export const CouponSelectionModes = {
  NONE: 'none',
  SHOP_COUPON: 'shop_coupon',
  USER_COUPON: 'user_coupon'
};

export const initialPaymentState = {
  point: {
    checked: false,
    amount: 0,
    convertedAmount: 0
  },
  credit: {
    checked: false,
    amount: 0,
    convertedAmount: 0
  },
  qrcode: {
    checked: false
  },
  cod: {
    checked: false
  },
  rawCash: {
    checked: false
  },
  mobileBanking: {
    checked: false
  },
  globalCard: {
    checked: false
  },
  qr2C2P: {
    checked: false
  },
  allNewebpay: {
    checked: false
  },
  linktopay: {
    checked: false
  }
};

const initialCartState = {
  cartId: null,
  targetCustomer: null,
  items: [],
  payment: initialPaymentState,
  couponSelectionMode: CouponSelectionModes.NONE,
  availableCoupons: [],
  selectedCoupon: null,
  conversionRateList: {},
  remark: '',
  shoppingCartSetting: {
    VAT: {
      isEnabled: false,
      percentage: 0,
      mode: 'included'
    },
    serviceCharge: {
      isEnabled: false,
      percentage: 0,
      mode: 'included'
    }
  }
};

export const ShoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState: initialCartState,
  reducers: {
    setTargetCustomerData: (state, action) => {
      if ('targetCustomer' in action.payload) state.targetCustomer = action.payload.targetCustomer;
      if ('targetBranch' in action.payload) state.targetBranch = action.payload.targetBranch;
    },
    updateShoppingCart: (state, action) => {
      state.items = action.payload.items;
      if (state.cartId !== undefined) {
        state.cartId = action.payload.cartId;
      }
    },
    updatePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload.paymentMethod;
    },
    couponsFetched: (state, action) => {
      state.availableCoupons = action.payload.availableCoupons;
    },
    setConversionRateList: (state, action) => {
      state.conversionRateList = action.payload;
    },
    setCurrentCoupon: (state, { payload }) => {
      state.selectedCoupon = payload.coupon;
      if (payload.couponSelectionMode) {
        state.couponSelectionMode = payload.couponSelectionMode;
      }
    },
    setCouponSelectionMode: (state, action) => {
      state.couponSelectionMode = action.payload;
    },
    setPayment: (state, action) => {
      state.payment = action.payload;
    },
    clearPayment: state => {
      state.payment = initialPaymentState;
    },
    setRemark: (state, action) => {
      state.remark = action.payload;
    },
    updateShoppingCartSetting: (state, action) => {
      state.shoppingCartSetting = action.payload;
    },
    catchError: (state, action) => {
      state.error = action.payload;
    }
  }
});
